/*
 * @Description:
 * @Author: 露露
 * @Date: 2019-08-21 08:47:38
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-12-06 14:59:53
 */
import { mapHelper } from 'common/utils'

//当前状态
const userType = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '新进',
    value: 1
  },
  {
    text: '试用',
    value: 2
  },
  {
    text: '正式',
    value: 3
  },
  {
    text: '离职',
    value: 4
  }
]
const {
  map: userTypeMap,
  setOps: setUserTypeOps
} = mapHelper.setMap(userType)


// 性别
const sex = [
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
const {
  map: sexMap,
  setOps: sexOps
} = mapHelper.setMap(sex)

// 账号状态
const status = [
  {
    text: '有效',
    value: 0
  },
  {
    text: '已过期',
    value: 1
  },
  {
    text: '已关闭',
    value: 2
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

export {
  statusMap,
  statusOps, 
  sexOps,
  sexMap, //性别
  userTypeMap,
  setUserTypeOps
}
