var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Salary-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showConfirm
                      ? _c("v-button", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          attrs: { type: "success", text: "保存" },
                          on: { click: _vm.submitBefore },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c("col2-block", { attrs: { title: "员工基本信息" } }, [
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "所属公司",
                          prop: "tenantId",
                          rules: [
                            {
                              required: true,
                              message: "请选择所属公司",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              ref: "regionNameRef",
                              attrs: {
                                placeholder: "输入公司名称",
                                width: _vm.width,
                                disabled: _vm.edit,
                              },
                              on: { onChange: _vm.tenantChange },
                              model: {
                                value: _vm.form.tenantId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tenantId", $$v)
                                },
                                expression: "form.tenantId",
                              },
                            },
                            "v-select2",
                            _vm.regionParams,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "员工姓名",
                          prop: "userName",
                          rules: [
                            {
                              required: true,
                              message: "请输入姓名",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入员工姓名",
                            maxlength: 20,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userName", $$v)
                            },
                            expression: "form.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "性别",
                          prop: "sex",
                          rules: [
                            {
                              required: true,
                              message: "请选择性别",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.sexOps, width: _vm.width },
                          model: {
                            value: _vm.form.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sex", $$v)
                            },
                            expression: "form.sex",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "证件类型",
                          prop: "cardType",
                          rules: [
                            {
                              required: true,
                              message: "请选择证件类型",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.cardTypeOps },
                          model: {
                            value: _vm.form.cardType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cardType", $$v)
                            },
                            expression: "form.cardType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "证件号码",
                          prop: "cardNum",
                          rules: [
                            {
                              required: true,
                              validator: _vm.validateCard,
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入证件号",
                            maxlength: 18,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.cardNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cardNum", $$v)
                            },
                            expression: "form.cardNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "出生日期",
                          prop: "birthday",
                          rules: [
                            {
                              required: true,
                              message: "请选择出生日期",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-datepicker", {
                          attrs: {
                            type: "date",
                            format: "YYYY-MM-DD",
                            maxDate: new Date(),
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.birthday,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "birthday", $$v)
                            },
                            expression: "form.birthday",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "民族",
                          prop: "nation",
                          rules: [
                            {
                              required: true,
                              message: "请选择民族",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            filterable: "",
                            options: _vm.nationOps,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.nation,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "nation", $$v)
                            },
                            expression: "form.nation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "籍贯",
                          prop: "nativePlaceArea",
                          rules: [
                            {
                              required: true,
                              message: "请选择籍贯",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("area-select", {
                          attrs: {
                            codes: _vm.nativePlaceArray,
                            "detail-addr": false,
                          },
                          on: {
                            "update:codes": function ($event) {
                              _vm.nativePlaceArray = $event
                            },
                            change: _vm.nativePlaceChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "户口所在地",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择户口所在地",
                                  trigger: "change",
                                },
                              ],
                              prop: "registeredResidenceArea",
                            },
                          },
                          [
                            _c("area-select", {
                              attrs: {
                                codes: _vm.residenceAddressArray,
                                "detail-addr": false,
                              },
                              on: {
                                "update:codes": function ($event) {
                                  _vm.residenceAddressArray = $event
                                },
                                change: _vm.areaChange,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "20px",
                              prop: "registeredResidenceAddress",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入详细地址",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: { placeholder: "请输入详细地址" },
                              model: {
                                value: _vm.form.registeredResidenceAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "registeredResidenceAddress",
                                    $$v
                                  )
                                },
                                expression: "form.registeredResidenceAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "talent-div" }, [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "现居住地",
                            rules: [
                              {
                                required: true,
                                message: "请选择现居住地",
                                trigger: "change",
                              },
                            ],
                            prop: "currentResidenceArea",
                          },
                        },
                        [
                          _c("area-select", {
                            attrs: {
                              codes: _vm.currentResidenceArray,
                              "detail-addr": false,
                            },
                            on: {
                              "update:codes": function ($event) {
                                _vm.currentResidenceArray = $event
                              },
                              change: _vm.currentResidenceChange,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "20px",
                            prop: "currentResidenceAddress",
                            rules: [
                              {
                                required: true,
                                message: "请输入详细地址",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "请输入详细地址" },
                            model: {
                              value: _vm.form.currentResidenceAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "currentResidenceAddress",
                                  $$v
                                )
                              },
                              expression: "form.currentResidenceAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "婚姻状况",
                          prop: "maritalStatus",
                          rules: [
                            {
                              required: true,
                              message: "请选择婚姻状况",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.maritalStatusOps,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.maritalStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "maritalStatus", $$v)
                            },
                            expression: "form.maritalStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "生育状况",
                          prop: "fertilityStatus",
                          rules: [
                            {
                              required: true,
                              message: "请选择生育状况",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.fertilityStatusOps,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.fertilityStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fertilityStatus", $$v)
                            },
                            expression: "form.fertilityStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "政治面貌",
                          prop: "politicalStatus",
                          rules: [
                            {
                              required: true,
                              message: "请选择政治面貌",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.politicsStatusOps },
                          model: {
                            value: _vm.form.politicalStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "politicalStatus", $$v)
                            },
                            expression: "form.politicalStatus",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "最高学历",
                          prop: "educationDegree",
                          rules: [
                            {
                              required: true,
                              message: "请选择最高学历",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.educationDegreeOps,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.educationDegree,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "educationDegree", $$v)
                            },
                            expression: "form.educationDegree",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "所学专业",
                          prop: "major",
                          rules: [
                            {
                              required: true,
                              message: "请输入所学专业",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入所学专业",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.major,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "major", $$v)
                            },
                            expression: "form.major",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "毕业院校",
                          prop: "graduateSchool",
                          rules: [
                            {
                              required: true,
                              message: "请输入毕业院校",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入毕业院校",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.graduateSchool,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "graduateSchool", $$v)
                            },
                            expression: "form.graduateSchool",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "第一学历",
                          prop: "firstEducationDegree",
                          rules: [
                            {
                              required: true,
                              message: "请选择第一学历",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.educationDegreeOps,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.firstEducationDegree,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "firstEducationDegree", $$v)
                            },
                            expression: "form.firstEducationDegree",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "所学专业",
                          prop: "firstMajor",
                          rules: [
                            {
                              required: true,
                              message: "请输入所学专业",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入所学专业",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.firstMajor,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "firstMajor", $$v)
                            },
                            expression: "form.firstMajor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "毕业院校",
                          prop: "firstGraduateSchool",
                          rules: [
                            {
                              required: true,
                              message: "请输入毕业院校",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入毕业院校",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.firstGraduateSchool,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "firstGraduateSchool", $$v)
                            },
                            expression: "form.firstGraduateSchool",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "talent-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "电子邮箱",
                          prop: "personalEmailAddress",
                          rules: [
                            {
                              required: true,
                              validator: _vm.validateEmail,
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入个人邮箱",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.personalEmailAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "personalEmailAddress", $$v)
                            },
                            expression: "form.personalEmailAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "联系电话",
                          prop: "phone",
                          rules: [
                            {
                              required: true,
                              validator: _vm.validatePhone,
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入手机号",
                            maxlength: 11,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "面试时间",
                          prop: "interviewDate",
                          rules: [
                            {
                              required: true,
                              message: "请选择面试时间",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-datepicker", {
                          attrs: {
                            type: "date",
                            format: "YYYY-MM-DD",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.interviewDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "interviewDate", $$v)
                            },
                            expression: "form.interviewDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "上传证件信息",
                          rules: [
                            {
                              required: true,
                              message: "请上传图片",
                              trigger: "change",
                            },
                          ],
                          prop: "fileUrlList",
                        },
                      },
                      [
                        _c("v-uploader", {
                          attrs: {
                            action: _vm.uploadURL,
                            imgUrls: _vm.form.fileUrlList,
                            limit: 10,
                            fileSize: 0.2,
                          },
                          on: {
                            "update:imgUrls": function ($event) {
                              return _vm.$set(_vm.form, "fileUrlList", $event)
                            },
                            "update:img-urls": function ($event) {
                              return _vm.$set(_vm.form, "fileUrlList", $event)
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "tip",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  注：1、可上传员工身份证、学位证等图片信息，支持JPG(JPEG)、GIF、PNG、BMP，单张不得超过200K\n                "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "28px" } },
                                    [
                                      _vm._v(
                                        "\n                  2、支持10张以内图片上传，可一次上传多张\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "col2-block",
                { attrs: { title: "员工录用信息" } },
                [
                  _c(
                    "div",
                    { staticClass: "salary-div" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "工号",
                            prop: "jobNum",
                            disabled: _vm.edit,
                            rules: [
                              {
                                required: true,
                                message: "请输入工号",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入工号",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobNum", $$v)
                              },
                              expression: "form.jobNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属部门",
                            prop: "orgId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属部门",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("select-tree", {
                            attrs: {
                              value: _vm.form.orgId,
                              data: _vm.treeSelectData,
                              placeholder: "查询所属部门",
                              treeData: _vm.treeData,
                              treeProps: _vm.treeProps,
                              highlightCurrent: true,
                              filterNodeMethod: _vm.filterNodeMethod,
                              "expand-on-click-node": false,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "orgId", $event)
                              },
                              "update:data": function ($event) {
                                _vm.treeSelectData = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "岗位",
                            prop: "duty",
                            rules: [
                              {
                                required: true,
                                message: "请输入岗位",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入岗位",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.duty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "duty", $$v)
                              },
                              expression: "form.duty",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "salary-div" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "职级",
                            prop: "jobRank",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validateSalary,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.gradeOps, width: _vm.width },
                            model: {
                              value: _vm.form.jobRank,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobRank", $$v)
                              },
                              expression: "form.jobRank",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "入职时间",
                            prop: "jobEnterDate",
                            rules: [
                              {
                                required: true,
                                message: "请选择入职时间",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              width: _vm.width,
                              format: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.form.jobEnterDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobEnterDate", $$v)
                              },
                              expression: "form.jobEnterDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "试用期期限",
                            rules: [
                              {
                                required: true,
                                message: "请输入试用期期限",
                                trigger: "blur",
                              },
                            ],
                            prop: "probationPeriod",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "请输入试用期期限",
                              max: 99999999999999999999,
                              min: 0,
                              width: _vm.width1,
                            },
                            model: {
                              value: _vm.form.probationPeriod,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "probationPeriod", $$v)
                              },
                              expression: "form.probationPeriod",
                            },
                          }),
                          _vm._v(" 个月\n          "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "salary-div" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "试用期薪资",
                            rules: [
                              {
                                required: true,
                                message: "请选择试用期薪资",
                                trigger: "change",
                              },
                            ],
                            prop: "probationSalary",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.setSalaryOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.probationSalary,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "probationSalary", $$v)
                              },
                              expression: "form.probationSalary",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否缴纳公积金",
                            rules: [
                              {
                                required: true,
                                message: "请选择是否缴纳公积金",
                                trigger: "change",
                              },
                            ],
                            prop: "providentFundFlag",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.setIsPaymentOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.providentFundFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "providentFundFlag", $$v)
                              },
                              expression: "form.providentFundFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否缴纳社保",
                            rules: [
                              {
                                required: true,
                                message: "请选择是否缴纳社保",
                                trigger: "change",
                              },
                            ],
                            prop: "socialSecurityFlag",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.setIsPaymentOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.socialSecurityFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "socialSecurityFlag", $$v)
                              },
                              expression: "form.socialSecurityFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("col2-block", { attrs: { title: "薪资信息" } }, [
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "薪资档级",
                              prop: "salaryGrade",
                              rules: [
                                {
                                  required: true,
                                  validator: _vm.validateSalary,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.salaryGradeOps,
                                width: _vm.width,
                                disabled: !_vm.form.tenantId,
                              },
                              on: { change: _vm.getbasicInfo },
                              model: {
                                value: _vm.form.salaryGrade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "salaryGrade", $$v)
                                },
                                expression: "form.salaryGrade",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "基本工资",
                            text: _vm.salaryStandardInfo.baseSalary || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "绩效工资",
                            text:
                              _vm.salaryStandardInfo.performanceSalary || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "社会保险",
                            text:
                              _vm.salaryStandardInfo.socialInsurance || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "社险补贴",
                            text:
                              _vm.salaryStandardInfo.socialInsuranceSubsidy ||
                              "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "公积金",
                            text: _vm.salaryStandardInfo.providentFund || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "公积金补贴",
                            text:
                              _vm.salaryStandardInfo.providentFundSubsidy ||
                              "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "通讯补贴",
                            text:
                              _vm.salaryStandardInfo.communicationSubsidy ||
                              "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "交通补贴",
                            text:
                              _vm.salaryStandardInfo.transportationSubsidy ||
                              "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "餐贴",
                            text: _vm.salaryStandardInfo.mealSubsidy || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "汽油费补贴",
                            text: _vm.salaryStandardInfo.petrolSubsidy || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "岗位补贴",
                            text: _vm.salaryStandardInfo.jobRankSubsidy || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "其他补贴",
                            text: _vm.salaryStandardInfo.otherSubsidy || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "考核奖",
                            text:
                              _vm.salaryStandardInfo.assessmentAward || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "高温费",
                            text:
                              _vm.salaryStandardInfo.highTemperatureFee || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "社保基数",
                            text:
                              _vm.salaryStandardInfo.socialInsuranceBase ||
                              "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "公积金基数",
                            text:
                              _vm.salaryStandardInfo.providentFundBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "养老基数",
                            text: _vm.salaryStandardInfo.pensionBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "生育基数",
                            text: _vm.salaryStandardInfo.fertilityBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "失业基数",
                            text:
                              _vm.salaryStandardInfo.unemploymentBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "工伤基数",
                            text: _vm.salaryStandardInfo.workInjuryBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "salary-div" },
                      [
                        _c("col2-item", {
                          attrs: {
                            label: "医疗基数",
                            text: _vm.salaryStandardInfo.medicalBase || "--",
                            span: 8,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "账号管理" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户名",
                        prop: "loginName",
                        rules: [
                          {
                            required: !_vm.loginNameDisabled,
                            message: "请输入用户名",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入用户名",
                          disabled: !!_vm.loginNameDisabled,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.loginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loginName", $$v)
                          },
                          expression: "form.loginName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                  }),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                    attrs: { type: "password" },
                  }),
                  !_vm.id || _vm.from
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用户密码",
                                prop: "pwd",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validatePass1,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                ref: "input1",
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入用户密码",
                                  width: _vm.width,
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.form.pwd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pwd", $$v)
                                  },
                                  expression: "form.pwd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-input", {
                            staticStyle: {
                              position: "fixed",
                              bottom: "-9999px",
                            },
                            attrs: { type: "password" },
                          }),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "20px",
                                prop: "pwd2",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validatePass,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                ref: "input2",
                                attrs: {
                                  type: "password",
                                  placeholder: "请确认用户密码",
                                  autocomplete: "new-password",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.pwd2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pwd2", $$v)
                                  },
                                  expression: "form.pwd2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号类型",
                        prop: "roleType",
                        rules: [
                          {
                            required: true,
                            message: "请选择账号类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.roleTypeFilterOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.roleTypeChange },
                        model: {
                          value: _vm.form.roleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roleType", $$v)
                          },
                          expression: "form.roleType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.roleType != "107" && _vm.form.roleType
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管理范围",
                                prop: "organizeId",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择管理范围",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      placeholder: "查询管理范围",
                                      subjoin: _vm.roleExtraParams,
                                      width: _vm.width,
                                    },
                                    on: { onChange: _vm.organizeChange },
                                    model: {
                                      value: _vm.form.organizeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "organizeId", $$v)
                                      },
                                      expression: "form.organizeId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.organizeParams,
                                  false
                                )
                              ),
                              _c("span", { staticClass: "mar-l" }, [
                                _vm._v("注：请选择管理项目或网格范围"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "角色",
                                prop: "roleId",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择角色",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: "查询角色",
                                      subjoin: _vm.rolesExtraParams,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.roleId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "roleId", $$v)
                                      },
                                      expression: "form.roleId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.roleParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号状态", prop: "accountStatus" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.statusMap[_vm.form.accountStatus]) +
                          "\n          "
                      ),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "关闭",
                              type: "text",
                              permission: "delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(0)
                              },
                            },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 2,
                                expression: "form.accountStatus === 2",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "开启",
                              type: "text",
                              permission: "delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(1)
                              },
                            },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "重置密码",
                              type: "text",
                              permission: "resetPwd",
                            },
                            on: { click: _vm.resetPwd },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "解锁",
                              type: "text",
                              permission: "unlocked",
                            },
                            on: { click: _vm.unlockID },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号有效截止日期",
                        prop: "accountValidDate",
                        rules: [
                          {
                            required: true,
                            message: "请选择账号有效截止日期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "date",
                          format: "YYYY-MM-DD",
                          width: _vm.width,
                          minDate: new Date(),
                        },
                        model: {
                          value: _vm.form.accountValidDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accountValidDate", $$v)
                          },
                          expression: "form.accountValidDate",
                        },
                      }),
                      _c("span", { staticClass: "mar-l" }, [
                        _vm._v("注：截止到当日23:59:59"),
                      ]),
                    ],
                    1
                  ),
                  _vm.form.roleType === "107" ||
                  (_vm.form.roleType === "101" && _vm.form.organizeId)
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属组织",
                              required: _vm.form.roleType === "107",
                              prop: "roleType",
                              rules: [
                                {
                                  validator: _vm.validatorOrgId,
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("chosenListPanel", {
                              attrs: { list: _vm.orgList },
                              on: {
                                "update:list": function ($event) {
                                  _vm.orgList = $event
                                },
                                select: _vm.toSelectOrg,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.oauthShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权物业服务", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                          _c("div", { staticStyle: { color: "#b75151" } }, [
                            _vm._v(
                              "\n            注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.oauthStoreShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权门店", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthStoreList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authStoreSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "组织列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getOrgRelationListURL,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "组织名称" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "组织标签" },
                      model: {
                        value: _vm.searchParams.orgTagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgTagId", $$v)
                        },
                        expression: "searchParams.orgTagId",
                      },
                    },
                    "v-select2",
                    _vm.orgTagParams,
                    false
                  )
                ),
                _vm.form.roleType === "107"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            subjoin: _vm.roleExtraParams,
                            label: "角色",
                          },
                          model: {
                            value: _vm.searchParams.roleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "roleId", $$v)
                            },
                            expression: "searchParams.roleId",
                          },
                        },
                        "v-select2",
                        _vm.roleParams,
                        false
                      )
                    )
                  : _vm._e(),
                _vm.form.roleType !== "101"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "关联项目" },
                          model: {
                            value: _vm.searchParams.communityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityId", $$v)
                            },
                            expression: "searchParams.communityId",
                          },
                        },
                        "v-select2",
                        _vm.communityParams,
                        false
                      )
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }