<template>
  <div class="Salary-wrapper">
    <form-panel
      ref="form"
      v-bind="config"
      :form="form"
      @update="update"
    >
      <col2-detail>
       <col2-block title="员工基本信息">
          <div class="talent-div">
            <!-- <el-form-item v-if="!regionVisible||isEdit"
                          label="所属公司">
              <div v-text="companyName"></div>
            </el-form-item> -->
            <el-form-item
              label="所属公司"
              prop="tenantId"
              :rules="[
                {
                  required: true,
                  message: '请选择所属公司',
                  trigger: 'change',
                },
              ]"
            >
              <v-select2
                ref="regionNameRef"
                v-model="form.tenantId"
                placeholder="输入公司名称"
                v-bind="regionParams"
                :width="width"
                :disabled="edit"
                @onChange="tenantChange"
              />
            </el-form-item>
            <el-form-item
              label="员工姓名"
              prop="userName"
              :rules="[
                { required: true, message: '请输入姓名', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.userName"
                placeholder="请输入员工姓名"
                :maxlength="20"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="性别"
              prop="sex"
              :rules="[
                { required: true, message: '请选择性别', trigger: 'change' },
              ]"
            >
              <v-select v-model="form.sex" :options="sexOps" :width="width" />
            </el-form-item>
          </div>
          <div class="talent-div">
            <el-form-item
              label="证件类型"
              prop="cardType"
              :rules="[
                {
                  required: true,
                  message: '请选择证件类型',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.cardType"
                :options="cardTypeOps"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="证件号码"
              prop="cardNum"
              :rules="[
                { required: true, validator: validateCard, trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.cardNum"
                placeholder="请输入证件号"
                :maxlength="18"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="出生日期"
              prop="birthday"
              :rules="[
                {
                  required: true,
                  message: '请选择出生日期',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.birthday"
                type="date"
                format="YYYY-MM-DD"
                :maxDate="new Date()"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="talent-div">
            <el-form-item
              label="民族"
              prop="nation"
              :rules="[
                { required: true, message: '请选择民族', trigger: 'change' },
              ]"
            >
              <v-select
                v-model="form.nation"
                filterable
                :options="nationOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="籍贯"
              prop="nativePlaceArea"
              :rules="[
                { required: true, message: '请选择籍贯', trigger: 'change' },
              ]"
            >
              <area-select
                :codes.sync="nativePlaceArray"
                :detail-addr="false"
                @change="nativePlaceChange"
              >
              </area-select>
            </el-form-item>
            <div style="display: flex">
              <el-form-item
                label="户口所在地"
                :rules="[
                  {
                    required: true,
                    message: '请选择户口所在地',
                    trigger: 'change',
                  },
                ]"
                prop="registeredResidenceArea"
              >
                <area-select
                  :codes.sync="residenceAddressArray"
                  :detail-addr="false"
                  @change="areaChange"
                >
                </area-select>
              </el-form-item>
              <el-form-item
                label-width="20px"
                prop="registeredResidenceAddress"
                :rules="[
                  {
                    required: true,
                    message: '请输入详细地址',
                    trigger: 'blur',
                  },
                ]"
              >
                <v-input
                  v-model="form.registeredResidenceAddress"
                  placeholder="请输入详细地址"
                />
              </el-form-item>
            </div>
          </div>
          <div class="talent-div">
            <div style="display: flex">
              <el-form-item
                label="现居住地"
                :rules="[
                  {
                    required: true,
                    message: '请选择现居住地',
                    trigger: 'change',
                  },
                ]"
                prop="currentResidenceArea"
              >
                <area-select
                  :codes.sync="currentResidenceArray"
                  :detail-addr="false"
                  @change="currentResidenceChange"
                >
                </area-select>
              </el-form-item>
              <el-form-item
                label-width="20px"
                prop="currentResidenceAddress"
                :rules="[
                  {
                    required: true,
                    message: '请输入详细地址',
                    trigger: 'blur',
                  },
                ]"
              >
                <v-input
                  v-model="form.currentResidenceAddress"
                  placeholder="请输入详细地址"
                />
              </el-form-item>
            </div>
          </div>
          <div class="talent-div">
            <el-form-item
              label="婚姻状况"
              prop="maritalStatus"
              :rules="[
                {
                  required: true,
                  message: '请选择婚姻状况',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.maritalStatus"
                :options="maritalStatusOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="生育状况"
              prop="fertilityStatus"
              :rules="[
                {
                  required: true,
                  message: '请选择生育状况',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.fertilityStatus"
                :options="fertilityStatusOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="政治面貌"
              prop="politicalStatus"
              :rules="[
                {
                  required: true,
                  message: '请选择政治面貌',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.politicalStatus"
                :options="politicsStatusOps"
              ></v-select>
            </el-form-item>
          </div>
          <div class="talent-div">
            <el-form-item
              label="最高学历"
              prop="educationDegree"
              :rules="[
                {
                  required: true,
                  message: '请选择最高学历',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.educationDegree"
                :options="educationDegreeOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="所学专业"
              prop="major"
              :rules="[
                { required: true, message: '请输入所学专业', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.major"
                placeholder="请输入所学专业"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="毕业院校"
              prop="graduateSchool"
              :rules="[
                { required: true, message: '请输入毕业院校', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.graduateSchool"
                placeholder="请输入毕业院校"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="talent-div">
            <el-form-item
              label="第一学历"
              prop="firstEducationDegree"
              :rules="[
                {
                  required: true,
                  message: '请选择第一学历',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.firstEducationDegree"
                :options="educationDegreeOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="所学专业"
              prop="firstMajor"
              :rules="[
                { required: true, message: '请输入所学专业', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.firstMajor"
                placeholder="请输入所学专业"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="毕业院校"
              prop="firstGraduateSchool"
              :rules="[
                { required: true, message: '请输入毕业院校', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.firstGraduateSchool"
                placeholder="请输入毕业院校"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="talent-div">
            <el-form-item
              label="电子邮箱"
              prop="personalEmailAddress"
              :rules="[
                { required: true, validator: validateEmail, trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.personalEmailAddress"
                placeholder="请输入个人邮箱"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="phone"
              :rules="[
                { required: true, validator: validatePhone, trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.phone"
                placeholder="请输入手机号"
                :maxlength="11"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="面试时间"
              prop="interviewDate"
              :rules="[
                {
                  required: true,
                  message: '请选择面试时间',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.interviewDate"
                type="date"
                format="YYYY-MM-DD"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="上传证件信息"
              :rules="[
                { required: true, message: '请上传图片', trigger: 'change' },
              ]"
              prop="fileUrlList"
            >
              <v-uploader
                :action="uploadURL"
                :imgUrls.sync="form.fileUrlList"
                :limit="10"
                :fileSize="0.2"
              >
                <template #tip>
                  <div>
                    注：1、可上传员工身份证、学位证等图片信息，支持JPG(JPEG)、GIF、PNG、BMP，单张不得超过200K
                  </div>
                  <div style="padding-left: 28px">
                    2、支持10张以内图片上传，可一次上传多张
                  </div>
                </template>
              </v-uploader>
            </el-form-item>
          </div>
        </col2-block>
        <col2-block title="员工录用信息">
          <div class="salary-div">
            <el-form-item
              label="工号"
              prop="jobNum"
              :disabled="edit"
              :rules="[
                { required: true, message: '请输入工号', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.jobNum"
                placeholder="请输入工号"
                :width="width"
              />
            </el-form-item>
            <el-form-item label="所属部门" prop="orgId"
              :rules="[ { required: true, message: '请选择所属部门', trigger: 'change' } ]"
            >
              <select-tree               
                :value.sync="form.orgId"
                :data.sync="treeSelectData"
                placeholder="查询所属部门"
                :treeData="treeData"
                :treeProps="treeProps"
                :highlightCurrent="true"
                :filterNodeMethod="filterNodeMethod"
                :expand-on-click-node="false"
              />
            </el-form-item>
            <el-form-item
              label="岗位"
              prop="duty"
              :rules="[
                { required: true, message: '请输入岗位', trigger: 'blur' },
              ]"
            >
              <v-input
                v-model="form.duty"
                placeholder="请输入岗位"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="职级"
              prop="jobRank"
              :rules="[
                { required: true, validator: validateSalary, trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.jobRank"
                :options="gradeOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="入职时间"
              prop="jobEnterDate"
              :rules="[
                {
                  required: true,
                  message: '请选择入职时间',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.jobEnterDate"
                type="date"
                :width="width"
                format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item
              label="试用期期限"
              :rules="[
                {
                  required: true,
                  message: '请输入试用期期限',
                  trigger: 'blur',
                },
              ]"
              prop="probationPeriod"
            >
              <!-- <v-select
                v-model="form.probationPeriod"
                :options="deadlineOps"
                :width="width"
              />              -->
              <v-input-number
                placeholder="请输入试用期期限"
                v-model="form.probationPeriod"
                :max="99999999999999999999"              
                :min="0"
                :width="width1"
              /> 个月
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="试用期薪资"
              :rules="[
                {
                  required: true,
                  message: '请选择试用期薪资',
                  trigger: 'change',
                },
              ]"
              prop="probationSalary"
            >
              <v-select
                v-model="form.probationSalary"
                :options="setSalaryOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="是否缴纳公积金"
              :rules="[
                {
                  required: true,
                  message: '请选择是否缴纳公积金',
                  trigger: 'change',
                },
              ]"
              prop="providentFundFlag"
            >
              <v-select
                v-model="form.providentFundFlag"
                :options="setIsPaymentOps"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="是否缴纳社保"
              :rules="[
                {
                  required: true,
                  message: '请选择是否缴纳社保',
                  trigger: 'change',
                },
              ]"
              prop="socialSecurityFlag"
            >
              <v-select
                v-model="form.socialSecurityFlag"
                :options="setIsPaymentOps"
                :width="width"
              />
            </el-form-item>
          </div>
          <col2-block title="薪资信息">
            <div class="salary-div">
              <el-form-item
                label="薪资档级"
                prop="salaryGrade"
                :rules="[
                  {
                    required: true,
                    validator: validateSalary,
                    trigger: 'blur',
                  },
                ]"
              >               
                <v-select
                v-model="form.salaryGrade"
                :options="salaryGradeOps"
                :width="width"
                :disabled="!form.tenantId"
                @change="getbasicInfo"
              />
              </el-form-item>
            </div>
            <div class="salary-div">
            <col2-item
              label="基本工资"
              :text="salaryStandardInfo.baseSalary || '--'"
              :span="8"
              required
            />
            <col2-item
              label="绩效工资"
              :text="salaryStandardInfo.performanceSalary || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="社会保险"
              :text="salaryStandardInfo.socialInsurance || '--'"
              :span="8"
              required
            />
            <col2-item
              label="社险补贴"
              :text="salaryStandardInfo.socialInsuranceSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="公积金"
              :text="salaryStandardInfo.providentFund || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="公积金补贴"
              :text="salaryStandardInfo.providentFundSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="通讯补贴"
              :text="salaryStandardInfo.communicationSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="交通补贴"
              :text="salaryStandardInfo.transportationSubsidy || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="餐贴"
              :text="salaryStandardInfo.mealSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="汽油费补贴"
              :text="salaryStandardInfo.petrolSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="岗位补贴"
              :text="salaryStandardInfo.jobRankSubsidy || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="其他补贴"
              :text="salaryStandardInfo.otherSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="考核奖"
              :text="salaryStandardInfo.assessmentAward || '--'"
              :span="8"
              required
            />
            <col2-item
              label="高温费"
              :text="salaryStandardInfo.highTemperatureFee || '--'"
              :span="8"
              required
            />
          </div>
            <div class="salary-div">
              <col2-item
                label="社保基数"
                :text="salaryStandardInfo.socialInsuranceBase || '--'"
                :span="8"
                required
              />
              <col2-item
                label="公积金基数"
                :text="salaryStandardInfo.providentFundBase || '--'"
                :span="8"
                required
              />
              <col2-item
                label="养老基数"
                :text="salaryStandardInfo.pensionBase || '--'"
                :span="8"
                required
              />
            </div>
            <div class="salary-div">
              <col2-item
                label="生育基数"
                :text="salaryStandardInfo.fertilityBase || '--'"
                :span="8"
                required
              />
              <col2-item
                label="失业基数"
                :text="salaryStandardInfo.unemploymentBase || '--'"
                :span="8"
                required
              />
              <col2-item
                label="工伤基数"
                :text="salaryStandardInfo.workInjuryBase || '--'"
                :span="8"
                required
              />
            </div>
            <div class="salary-div">
              <col2-item
                label="医疗基数"
                :text="salaryStandardInfo.medicalBase || '--'"
                :span="8"
                required
              />
            </div>
          </col2-block>
        </col2-block>
        <col2-block title="账号管理">
          <!-- 这里多出的输入框是为了避免浏览器默认填充账号密码 -->
          <el-form-item label="用户名" prop="loginName"
            :rules="[{required: !loginNameDisabled, message: '请输入用户名', trigger: ['blur', 'change']}]"
          >
            <v-input v-model="form.loginName"
              placeholder="请输入用户名"
              :disabled="!!loginNameDisabled"
              :width="width" />
          </el-form-item>
          <v-input style="position:fixed;bottom: -9999px;" />
          <v-input type="password" style="position:fixed;bottom: -9999px;" />
          <div style="display: flex;" v-if="!id||from">
            <el-form-item label="用户密码" prop="pwd"
              :rules="[{ required: true, validator: validatePass1, trigger: 'blur' }]"
            >
              <v-input ref="input1" v-model="form.pwd" type="password" placeholder="请输入用户密码" :width="width" autocomplete="new-password"/>
            </el-form-item>
            <v-input type="password" style="position:fixed;bottom: -9999px;" />
            <el-form-item label-width="20px" prop="pwd2"
              :rules="[{required: true, validator: validatePass, trigger: 'blur'}]"
            >
              <v-input
                ref="input2"
                v-model="form.pwd2"
                type="password"
                placeholder="请确认用户密码"
                autocomplete="new-password"
                :width="width"
              />
            </el-form-item>
          </div>
          <!-- end -->
          <el-form-item label="账号类型" prop="roleType"
            :rules="[ { required: true, message: '请选择账号类型', trigger: 'change' } ]"
          >
            <v-select
              v-model="form.roleType"
              :options="roleTypeFilterOps"
              :width="width"
              @change="roleTypeChange"
            />
          </el-form-item>
          <div v-if="form.roleType != '107' && form.roleType">
            <el-form-item label="管理范围" prop="organizeId"
              :rules="[ { required: true, message: '请选择管理范围', trigger: 'change' } ]"
            >
              <v-select2
                style="display: inline-block"
                placeholder="查询管理范围"
                v-model="form.organizeId"
                :subjoin="roleExtraParams"
                v-bind="organizeParams"
                :width="width"
                @onChange="organizeChange"
              />
              <span class="mar-l">注：请选择管理项目或网格范围</span>
            </el-form-item>
            <el-form-item label="角色" prop="roleId"
              :rules="[ { required: true, message: '请选择角色', trigger: 'change' } ]"
            >
              <v-select2
                placeholder="查询角色"
                v-model="form.roleId"
                :subjoin="rolesExtraParams"
                v-bind="roleParams"
                :width="width"
              ></v-select2>
            </el-form-item>
          </div>
          <el-form-item label="账号状态" prop="accountStatus">
            {{statusMap[form.accountStatus]}}
            <v-button text="关闭" type="text" permission="delete"
              class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
              v-show="form.accountStatus === 0" @click="updateStatus(0)"
            />
            <v-button text="开启" type="text" permission="delete"
              class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
              v-show="form.accountStatus === 2" @click="updateStatus(1)"
            />
            <v-button text="重置密码" type="text" permission="resetPwd"
               class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
               v-show="form.accountStatus === 0" @click="resetPwd"
            />
            <v-button text="解锁" type="text" permission="unlocked"
               class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
               v-show="form.accountStatus === 0" @click="unlockID"
            />
          </el-form-item>
          <el-form-item label="账号有效截止日期" prop="accountValidDate"
            :rules="[ { required: true, message: '请选择账号有效截止日期', trigger: 'change' } ]"
          >
            <v-datepicker type="date" format="YYYY-MM-DD"
              v-model="form.accountValidDate"
              :width="width" :minDate="new Date()"
            />
            <span class="mar-l">注：截止到当日23:59:59</span>
          </el-form-item>
          <template v-if=" form.roleType === '107' ||
            (form.roleType === '101' && form.organizeId)"
          >
            <el-form-item
              label="所属组织"
              :required="form.roleType === '107'"
              prop="roleType"
              :rules="[{ validator: validatorOrgId, trigger: 'change' }]"
            >
              <chosenListPanel
                :list.sync="orgList"
                @select="toSelectOrg"
              ></chosenListPanel>
            </el-form-item>
          </template>
          <el-form-item v-if="oauthShow" label="授权物业服务" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authSelect(val, item, index)"
              />
            </div>
            <div style="color: #b75151">
              注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.
            </div>
          </el-form-item>
          <el-form-item v-if="oauthStoreShow" label="授权门店" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthStoreList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authStoreSelect(val, item, index)"
              />
            </div>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <!-- <v-button v-if="showReturn" text="返回" @click="returnClick" /> -->
        <v-button type="success" v-loading="loading" text="保存" @click="submitBefore" v-if="showConfirm" />
      </template>
    </form-panel>
    <multi-select
      title="组织列表"
      :isShow.sync="isShow"
      :searchUrl="getOrgRelationListURL"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.orgName" label="组织名称"></v-input>
        <v-select2
          v-model="searchParams.orgTagId"
          v-bind="orgTagParams"
          label="组织标签"
        ></v-select2>
        <v-select2
          v-if="form.roleType === '107'"
          v-model="searchParams.roleId"
          :subjoin="roleExtraParams"
          v-bind="roleParams"
          label="角色"
        ></v-select2>
        <v-select2
          v-if="form.roleType !== '101'"
          v-model="searchParams.communityId"
          v-bind="communityParams"
          label="关联项目"
        ></v-select2>
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  getInitInfoURL,
  getEnterOrgTreeListURL,
  getSalaryStandardByTenantAndGradeURL,
  uploadURL,
  getRoleTypeURL,
  getOrganizeURL,
  getRolesURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  addManageUserURL,
  updateManageUserURL,
  updateTypeURL,
  getViewOauthURL,
  getStoreListURL,
  getUserInfoURL,
  resetPwgURL,
  unlockedURL,
  batchURL,
  // 新接口
  updateEnterpriseUserInfoURL,
  addEnterpriseUserInfoURL,
  getEnterpriseUserInfoDetailURL,
  queryURL
} from "./api";
import {
  statusOps,
  statusMap,
  sexOps,
  sexMap
} from './map'
import {
  mobileRegular,
  idNumberRegular,
  emailAddressRegular,
} from "common/regular";
import { vUploader } from "components/control";
import { regionParams } from 'common/select2Params'
import {
  Col2Detail,
  Col2Block,
  Col2Item,
  ChosenListPanel,
  SelectTree,
  MultiSelect,
  AreaSelect
} from 'components/bussiness'
import { vCheckbox } from 'components/control/index'
import { communityParams } from 'common/select2Params'
export default {
  name: "CommunicationForm",
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    AreaSelect,
    vUploader,
    SelectTree,
    vCheckbox,
    MultiSelect,
    ChosenListPanel,
  },
  data() {
    return {
      showConfirm: true,
      regionParams,
      uploadURL: uploadURL,
      width: 182,
      width1:150,
      pageLoading: false, // 页面加载..
      loading: false, // 保存按钮加载..
      id: '',
      newId: '', // 获取新接口详情
      orgId: '', // 组织id
      orgParentId: '', // 组织父id
      from: '',
      submitContentType: 'application/json;charset=UTF-8',
      config: {
        queryUrl: getUserInfoURL
      },
      nativePlaceArray: [],
      residenceAddressArray: [],
      currentResidenceArray: [],
      form: {
        // old
        id: '',
        userName: '',
        sex: undefined, // 新加 性别
        birthday: '', // 新加 生日
        emailAddress: '', // 新加 个人邮箱
        phone: '', // 手机号
        loginName: undefined,
        pwd: '',
        pwd2: '',
        duty: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        storeIds: '',
        organizeId: '',
        // headIcon: undefined,
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded,

        // new
        fromFlag: "2",
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        registeredResidenceProvince: "", //户口所在地
        registeredResidenceCity: "",
        registeredResidenceArea: "",
        registeredResidenceAddress: "",
        currentResidenceProvince: "", //现在居住地
        currentResidenceCity: "",
        currentResidenceArea: "",
        currentResidenceAddress: "",
        tenantId: "",
        userName: "",
        sex: undefined,
        cardType: undefined,
        cardNum: "",
        birthday: "",
        nation: undefined,
        maritalStatus: undefined,
        fertilityStatus: undefined,
        politicalStatus: undefined,
        educationDegree: undefined,
        major: "",
        graduateSchool: "",
        firstMajor: "",
        firstGraduateSchool: "",
        firstEducationDegree: undefined,
        personalEmailAddress: "",
        mobileNum: "",
        interviewDate: "",
        fileUrlList: [],
        jobNum: "",
        orgId: "",
        jobRank: undefined,
        jobEnterDate: "",
        probationPeriod: undefined,
        robationSalary: undefined,
        providentFundFlag: undefined,
        socialSecurityFlag: undefined,
        salaryGrade: undefined,
        probationSalary: undefined,
        accountStatus: 0, // 账号状态,默认有效
        accountValidDate: '',
        adminDuty: ''
      },
      oldForm: { // 原有页面的字段 - accountMgrForm
        id: '',
        userName: '',
        sex: '',
        birthday: '',
        emailAddress: '',
        phone: '',
        loginName: undefined,
        pwd: '',
        pwd2: '',
        duty: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        storeIds: '',
        organizeId: '',
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded
      },
      newForm: { // 新页面的字段 - 2020.12.9 更改
        // id: '', // 此id与老接口获取详情id并不相同
        // new
        fromFlag: "2",
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        registeredResidenceProvince: "", //户口所在地
        registeredResidenceCity: "",
        registeredResidenceArea: "",
        registeredResidenceAddress: "",
        currentResidenceProvince: "", //现在居住地
        currentResidenceCity: "",
        currentResidenceArea: "",
        currentResidenceAddress: "",
        tenantId: "",
        userName: "",
        sex: undefined,
        cardType: undefined,
        cardNum: "",
        birthday: "",
        nation: undefined,
        maritalStatus: undefined,
        fertilityStatus: undefined,
        politicalStatus: undefined,
        educationDegree: undefined,
        major: "",
        graduateSchool: "",
        firstMajor: "",
        firstGraduateSchool: "",
        firstEducationDegree: undefined,
        personalEmailAddress: "",
        mobileNum: "",
        interviewDate: "",
        fileUrlList: [],
        jobNum: "",
        orgId: "",
        jobRank: undefined,
        jobEnterDate: "",
        probationPeriod: undefined,
        robationSalary: undefined,
        providentFundFlag: undefined,
        socialSecurityFlag: undefined,
        salaryGrade: undefined,
        probationSalary:undefined,
        accountStatus: '', // 账号状态,默认有效
        accountValidDate: '',
        adminDuty: ''
      },
      edit: false,
      salaryStandardInfo: {}, //员工基础信息
      setSalaryOps: [],
      setIsPaymentOps:[],
      gradeOps: [], //职级
      salaryGradeOps: [], //薪资档级
      cardTypeOps: [], //证件类型
      politicsStatusOps: [], //政治面貌
      nationOps: [], //民族
      sexOps: sexOps(), //性别
      fertilityStatusOps: [],
      maritalStatusOps: [],
      educationDegreeOps: [], //学历
      nativePlaceOps: [], //籍贯
      areaList: [], // 户口所在地
      /* select2控件 */
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      oauthList: [], // 授权物业服务列表
      oauthStoreList: [], // 授权门店列表
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeData: [],
      orgType: 2, // 组织类型
      /* 多选框控件 */
      getOrgRelationListURL,
      isShow: false,
      communityParams,
      searchParams: {
        orgName: '',
        orgTagId: '',
        roleId: undefined,
        communityId: ''
      },
      orgTagParams: {
        searchUrl: getDropDownSearchTagURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      orgList: [],
      /* 辅助状态 */
      oauthIds: [], // 授权物业ids
      oauthStoreIds: [], // 授权门店ids
      roleType: '', // 记录初始的角色类型
      userName: '', // 记录初始的用户名
      loginName: '', // 记录初始的登录名
      isBack: false, // 获取页面详情时,账号类型修改不清空相关数据
      roleTypeOps: [], // 账号类型
      statusOps,
      statusMap
    };
  },
  computed: {
    isEdit() {
      return this.$route.query.id;
    },
    /* 全局变量 */
    userInfo () {
      return this.$store.state.userInfo
    },
    // editMobile () { // 手机号控件是否禁用
    //   return this.$store.getters.getPermission('editMobile')
    // },
    /* 表单提交and请求 */
    extraParams () {
      let params = { roleType: this.form.roleType }
      if (this.form.roleType === '101') {
        params = {
          roleType: this.form.roleType,
          communityId: this.form.organizeId
        }
      }
      return params
    },
    submitUrl () {
      return this.id ? (this.from? addManageUserURL:updateManageUserURL) : addManageUserURL
    },
    submitUrl2 () {
      return this.id ? (this.from? addEnterpriseUserInfoURL:updateEnterpriseUserInfoURL) : addEnterpriseUserInfoURL
    },
    submitMethod () {
      return this.id ? (this.from?'POST':'PUT') : 'POST'
    },
    /* select2 控件 */
    roleExtraParams () { // 管理范围
      return {
        roleType: this.form.roleType
      }
    },
    rolesExtraParams () { // 角色
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.form.roleType,
        organizeId: organizeId || this.form.organizeId
      }
    },
    /* vcheckbox 多选项 */
    oauthShow () { // 授权物业服务
      let arr = ['100', '101', '102']
      return arr.indexOf(this.form.roleType) > -1
    },
    oauthStoreShow () {
      let arr = ['101']
      return arr.indexOf(this.form.roleType) > -1
    },
    /* 多选框 */
    orgTableHeader () { // 多选框表头
      let show = this.form.roleType === '107'
      return [
        {
          prop: 'orgName',
          label: '组织名称'
        },
        {
          prop: 'orgTagNames',
          label: '组织标签'
        },
        {
          prop: 'communityNames',
          label: '关联项目'
        },
        {
          prop: 'roleName',
          label: '角色名称',
          show: show
        }
      ]
    },
    responseParams () { // 多选框响应字段
      let responseParams1 = {
        id: 'orgId',
        name: 'orgName'
      }

      let responseParams2 = {
        id: 'id',
        name: ['orgName', 'roleName']
      }

      return this.form.roleType === '101' ? responseParams1 : responseParams2
    },
    /* 过滤 */
    roleTypeFilterOps () {
      // 2/3 物业管理公司/社区,账号只能选集团以下的角色, 1 平台职能部门 只能选 集团+系统
      if (Number(this.orgType) === 1) {
        return this.roleTypeOps.filter(v => v.value === '100' || v.value === '106')
      } else if (Number(this.orgType) === 2 || Number(this.orgType) === 3) {
        return this.roleTypeOps.filter(v => v.value !== '100' && v.value !== '106')
      }
      return this.roleTypeOps
    },
    /* 控件的显隐以及禁用 */
    loginNameDisabled () { // 登录名禁止修改
      let arr = ['100', '102', '101', '107']
      return (arr.indexOf(this.form.roleType) < 0 || (this.id && this.id.length > 0 && !this.from))
    }
  },
  // watch: {
  //   defaultOrganizeId (newValue) {
  //     this.form.organizeId = newValue
  //   }
  // },
  created(){
    this.id = this.$route.query.id || ''
    this.orgId = this.$route.query.orgId || ''
    this.newId = this.$route.query.newId || ''
    if(this.$route.query.isLook){
      this.showConfirm=false
    }
  },
  mounted() {
    const { id } = this.$route.query;
    /** 以下走嵌入页面逻辑 */
    if(this.$route.query.from=='talent'){
      console.log('mmmmmm')
      this.from = 'talent'
      this.config.queryUrl = queryURL
      this.$refs.form.getData({ id });
      this.$setBreadcrumb('新增')
    } else {
      this.id && this.$refs.form.getData({ userId: this.id })
      this.id ? this.$route.query.isLook? this.$setBreadcrumb('查看'): this.$setBreadcrumb('编辑') : this.$setBreadcrumb('新增')
      // if(this.$route.query.isLook){
      //   this.$setBreadcrumb('查看')
      // }
    }
    this.getSelectList()
    // this.getTreeList()
    this.getRoleType()
  },
  methods: {
    getbasicInfo(data, echo) {
      console.log(data,'7777')
      if(data){
        this.getbasicInfoList()

      } else{
        this.salaryStandardInfo = {}
      }
      
      if (!echo) {
        console.log(data, echo);
      }
    },
    getbasicInfoList() {
      let params = {
        tenantId: this.form.tenantId,
        salaryGrade: this.form.salaryGrade
      };
      this.$axios.get(getSalaryStandardByTenantAndGradeURL, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.salaryStandardInfo = res.data;
        } else{
          this.form.salaryGrade = undefined
          this.salaryStandardInfo = {}
        }
      });
    },
    validateSalary(rule, value, callback) {
      const salaryRegular = /\d+|\d.\d+|0\.\d+/;
      if (value === "") {
        callback(new Error("该项不能为空"));
      } else if (!salaryRegular.test(value)) {
        callback(new Error("格式不正确，请输入数字"));
      } else {
        callback();
      }
    },
    getSelectList() {
      // 其余下拉选项数据
      this.$axios.get(getInitInfoURL).then((res) => {
        if (res.status === 100 && res.data) {
          const {
            educationDegreeList,
            nationList,
            cardTypeList,
            politicalStatusList,
            fertilityList,
            isMarryList,
            jobRankList,
            yesOrNoList,
            salaryGradeList,
            probationSalaryList
          } = res.data;
          this.educationDegreeOps = educationDegreeList.map((item) => ({
            text: item,
            value: item,
          }));
          this.nationOps = nationList.map((item) => ({
            text: item,
            value: item,
          }));
          this.cardTypeOps = cardTypeList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.politicsStatusOps = politicalStatusList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.fertilityStatusOps = fertilityList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.maritalStatusOps = isMarryList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.gradeOps = jobRankList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.setIsPaymentOps = yesOrNoList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.salaryGradeOps = salaryGradeList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.setSalaryOps = probationSalaryList.map((item, index) => ({
            text: item,
            value: item,
          }));

        }
      });
    },
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    },
    validateCard(rule, value, callback) {
      if (value === "") {
        callback(new Error("请填写证件号"));
      } else if (this.form.cardType=='身份证') {
        if(!idNumberRegular.test(value)){
          callback(new Error("证件号不正确"));
        } else {
          callback();
        }    
      } else {
        callback();
      }
    },
    validateEmail(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入电子邮箱"));
        return;
      }
      if (!emailAddressRegular.test(value)) {
        callback(new Error("邮箱格式不正确"));
      } else {
        callback();
      }
    },
    areaChange(val) {
      // 户口所在地
      if (val.area) {
        this.form.registeredResidenceCity = val.city.code;
        this.form.registeredResidenceArea = val.area.code;
        this.form.registeredResidenceProvince = val.province.code;
      } else {
        this.form.registeredResidenceCity = "";
        this.form.registeredResidenceArea = "";
        this.form.registeredResidenceProvince = "";
      }
    },
    nativePlaceChange(val) {
      //籍贯
      if (val.area) {
        this.form.nativePlaceProvince = val.province.code;
        this.form.nativePlaceCity = val.city.code;
        this.form.nativePlaceArea = val.area.code;
      } else {
        this.form.nativePlaceProvince = "";
        this.form.nativePlaceCity = "";
        this.form.nativePlaceArea = "";
      }
    },
    currentResidenceChange(val) {
      //现居住地
      if (val.area) {
        this.form.currentResidenceProvince = val.province.code;
        this.form.currentResidenceCity = val.city.code;
        this.form.currentResidenceArea = val.area.code;
      } else {
        this.form.currentResidenceProvince = "";
        this.form.currentResidenceCity = "";
        this.form.currentResidenceArea = "";
      }
    },
    tenantChange (option, echo) {
      this.getTreeList()
      
      console.log(option,echo)
      if (!echo) {
        this.form.salaryGrade = undefined
      this.form.tenantChange = ''
      }
    },
    getTreeList () { // 所属部门树数据
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.orgType}&&tenantId=${this.form.tenantId}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        })
    },
    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
          console.log(this.form.roleType)
          // !this.form.roleType && (this.form.roleType = data[0].id)
        }
      })
    },
    validatorOrgId (rule, value, callback) { // 所属组织
      if (this.form.roleType !== '107') {
        callback()
      } else if (this.orgList.length === 0) {
        callback(new Error('请选择所属组织'))
      } else {
        callback()
      }
    },
    validatePass1 (rule, value, callback) { // 验证登录密码
      if (value === '') {
        callback(new Error('请输入用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else {
        callback()
      }
    },
    validatePass (rule, value, callback) { // 验证再次输入的密码
      if (value === '') {
        callback(new Error('请确认用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    /** 辅助类方法 */
    inputDefault (e) { // 阻止默认事件
      for (let i = 0; i < 3; i += 1) {
        this.$refs['index' + i].onmousedown = function () {
          if (e && e.preventDefault) {
            // 现代浏览器阻止默认事件
            e.preventDefault()
          } else {
            // IE阻止默认事件
            window.event.returnValue = false
          }
          return false
        }
      }
    },
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    },
    roleTypeChange () { // 账号类型
      if (this.isBack) {
        this.isBack = false
        return false
      }
      this.orgList = []
      this.oauthList = []
      this.oauthIds = []
      this.oauthStoreList = []
      this.oauthStoreIds = []
      this.$refs.form.clearValidate()
    },
    organizeChange (val) { // 管理范围
      if (this.oauthShow) { // 如果是授权物业
        if (val && val.id) {
          this.getViewOauthList(val.id)
        } else {
          this.oauthList = []
          this.oauthIds = []
        }
      }
      if (this.oauthStoreShow) { // 如果是授权门店
        if (val && val.id) {
          this.getViewOauthStoreList(val.id)
        } else {
          this.oauthStoreList = []
          this.oauthStoreIds = []
        }
      }
    },
    authSelect (val, item, index) {
      if (val) {
        this.oauthIds.push(item.id)
      } else {
        let index = this.oauthIds.indexOf(item.id)
        this.oauthIds.splice(index, 1)
      }
    },
    authStoreSelect (val, item, index) { // 授权门店
      if (val) {
        this.oauthStoreIds.push(item.id)
      } else {
        let index = this.oauthStoreIds.indexOf(item.id)
        this.oauthStoreIds.splice(index, 1)
      }
    },
    onOrgSelect (data) { // 组织多选框内容
      console.log(data, '多选框选择内容')
    },
    /* 接口类 */
    upload2Request () {
      this.pageLoading = true
      this.$axios.get(`${getEnterpriseUserInfoDetailURL}?id=${this.newId}`)
        .then((res) => {
          if (res.status === '100' || res.status === 100) {
            const { data } = res
            this.upload2(data)
          }
        }).finally(() => {
          this.pageLoading = false
        })
    },
    async requestOldSubmit (params) {
      this.loading = true
      let config = {
        method: this.submitMethod,
        url: this.submitUrl,
        headers: {
          'Content-Type': this.submitContentType
        },
        data: params,
        transformRequest: [function (data) { return JSON.stringify(data) }]
      }
      this.$axios(config).then(async res => {
        if (res.status === 100 || res.status === '100') {
          // let data = res.data
          // let msg = res.msg
          console.log('1. 老接口请求成功')
          let { data, msg } = res
          let result = await this.submitSuccess(data, msg)
          console.log(this.orgId, this.orgParentId, '6.orgParentId 和 this.orgId')
          if (!result) {
            return
          }
          this.$message.success('保存成功')
          this.goBack()
        } else {
          this.loading = false
          // this.submitError && this.submitError(res)
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    async requestNewSubmit (params) {
      console.log('3. 新表接口开始调')
      let result = false
      let res = await this.$axios.post(this.submitUrl2, params)
      console.log('4. 新表接口调成功')
      this.loading = false
      if (Number(res.status) === 100 && res.msg === 'ok') {
        console.log(JSON.stringify(this.treeSelectData), '5. 修改orgId和orgParentId')
        this.orgId = this.treeSelectData[this.treeProps.id]
        this.orgParentId = this.treeSelectData[this.treeProps.parentId]
        result = true
      }
      return result
    },
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
          console.log(this.form.roleType)
          // !this.form.roleType && (this.form.roleType = data[0].id)
        }
      })
    },
    requestToAllConfig (id) { // 2.1 开启邑生活物管端APP待办项设置的请求接口
      this.$axios.post(`${updateTypeURL}?userId=${id}`).then((res) => {
        if (res.status === 100) {
          console.log('成功了')
        }
      })
    },
    getViewOauthList (id) { // 其余控件方法 - 管理范围 - 授权物业
      let params = {
        organizeId: id,
        userType: this.form.roleType
      }
      // this.id && (params.userId = this.id)
      if (this.id&&!this.from) {
        params.userId = this.id
      }
      this.$axios.post(getViewOauthURL, params).then((res) => {
        if (res.status === '100') {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected || item.name === '集团报事') {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: item.name === '集团报事' ? true : Number(item.selected) === 1
            })
          })
          this.oauthList = oauthList
          this.oauthIds = ids
        }
      })
    },
    getViewOauthStoreList (id) { // 其余控件方法-管理范围-授权门店
      let data = {
        communityId: id,
        userType: this.form.roleType
      }
      if(this.id&&!this.from){
        data.userId = this.id
      }
      // (this.id&&!this.from) && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected) {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: Number(item.selected) === 1
            })
          })
          this.oauthStoreList = oauthList
          this.oauthStoreIds = ids
        }
      })
    },
    resetPwd () { // 重置密码
      this.$axios.put(
        resetPwgURL,
        this.$qs.stringify({ userId: this.id })
      ).then((res) => {
        if (res.status === 100) {
          this.$alert(res.data)
        }
      })
    },
    unlockID () { // 解锁
      this.$axios.post(
        unlockedURL,
        {
          userId: this.id,
          userName: this.loginName
        }
      ).then((res) => {
        if (Number(res.status) === 100) {
          this.$alert('账号解锁成功！')
        }
      })
    },
    updateStatus (status) { // 关闭or开启账号
      // 操作日志添加操作对象 规则：userName-loginName
      // let dataObject = dataArr.join(';')
      let dataObject = `${this.userName}-${this.loginName}`

      let _this = this
      let postData = {
        userIds: this.id,
        status, // 0 关闭 1 开启
        dataObject: dataObject
      }
      _this.$axios({
        method: 'put',
        url: batchURL,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.form.getData({ userId: this.id })
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    /* 其余控件方法 */
    toSelectOrg () { // 打开组织多选框
      this.isShow = true
    },
    update (data) {
      if(this.from == 'talent'){
        Object.keys({ ...this.newForm }).forEach(key => {
        if (key === 'id') { // form字段id为旧id,不能覆盖
          return
        }
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
        this.form.talentUserId = this.$route.query.id
      })
      this.form.phone = data.mobileNum
      // this.$set(this.newForm, 'id', data.id)
      // 级联选择器
      this.nativePlaceArray = [
        data.nativePlaceProvince,
        data.nativePlaceCity,
        data.nativePlaceArea,
      ];
      this.residenceAddressArray = [
        data.registeredResidenceProvince,
        data.registeredResidenceCity,
        data.registeredResidenceArea,
      ];
      this.currentResidenceArray = [
        data.currentResidenceProvince,
        data.currentResidenceCity,
        data.currentResidenceArea,
      ];

      }else{
        this.isBack = true
      /**
       * 老旧接口，无详细业务，且后端无档期查询接口逻辑，因而保留原有代码
       * 请求详情接口为最早的old接口，与第一次旧接口保存字段并非一一对应，所以衍生出此代码
       *
      */
      // 1. 字段匹配值
      this.form.id = data.id
      this.form.sex = data.sex
      this.form.birthday = data.birthday
      this.form.emailAddress = data.emailAddress || ''
      this.form.duty = data.duty
      this.form.loginName = data.loginName
      this.form.phone = data.phone
      this.form.desensitization = !data.desensitization
      this.form.userName = data.userName
      // this.newseeUserName = data.nsUserName
      this.form.organizeId = data.orgId
      this.form.roleId = data.roleId
      this.form.roleType = JSON.stringify(data.userType)
      // 2. 记录部分字段的初始状态
      this.userName = data.userName
      this.loginName = data.loginName
      this.roleType = JSON.stringify(data.userType)
      // 3. 组织多选框的列表根据权限显示不同数据
      if (this.form.roleType === '107') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgRoleName,
            id: item.roleId,
            obj: item
          })) : []
      }
      if (this.form.roleType === '101') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgName,
            id: String(item.orgId),
            obj: item
          })) : []
      }
      // 4. 请求新接口详情
      this.upload2Request()
      /** end */
      // Object.keys({ ...this.form, id: '' }).forEach(key => {
      //   this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      // })

      }
      
    },
    upload2 (data) {
      if (!data) { return }
      Object.keys({ ...this.newForm }).forEach(key => {
        if (key === 'id') { // form字段id为旧id,不能覆盖
          return
        }
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      this.$set(this.newForm, 'id', data.id)
      // 级联选择器
      this.nativePlaceArray = [
        data.nativePlaceProvince,
        data.nativePlaceCity,
        data.nativePlaceArea,
      ];
      this.residenceAddressArray = [
        data.registeredResidenceProvince,
        data.registeredResidenceCity,
        data.registeredResidenceArea,
      ];
      this.currentResidenceArray = [
        data.currentResidenceProvince,
        data.currentResidenceCity,
        data.currentResidenceArea,
      ];
    },
    /* 表单请求之前and请求成功 */
    async submitBefore () { // 1. 调保存接口前
      // 0. 先验证表单 注释内容存在问题
      // let result = this.$refs.form.validate()
      this.$refs.form.$refs.form.validate((result) => {
        if (!result) {
          return
        }
        // 1. 深度克隆表单数据
        const oldParams = _.cloneDeep(this.form)
        this.setOldParams(oldParams)
      })
    },
    setOldParams (params) { // 2. 设置老接口参数
      // 1. 去除掉值为undefined的字段
      let oldForm = {}
      let keys = Object.keys(this.oldForm)
      keys.forEach(k => {
        if (params[k] !== undefined) {
          oldForm[k] = params[k]
        }
      })
      // 2. 字段类型修改
      oldForm.desensitization = oldForm.desensitization ? 0 : 1
      // 3. 字段名称修改
      oldForm.mobileNum = oldForm.phone
      oldForm.adminDuty = oldForm.duty
      oldForm.password = oldForm.pwd
      oldForm.userType = oldForm.roleType
      oldForm.orgId = oldForm.organizeId
      // 4. 字段值填充
      this.orgList.length > 0 && (oldForm.orgIds = this.orgList.map((item) => item.id))
      this.oauthIds.length > 0 && (oldForm.subIds = this.oauthIds)
      this.oauthStoreIds.length > 0 && (oldForm.storeIds = this.oauthStoreIds)
      // 4. 区分新增和编辑，编辑需要保留原有字段, 新增则不需要
      if (!this.id||this.from) {
        delete oldForm.phone
        delete oldForm.duty
        delete oldForm.pwd
        delete oldForm.pwd2
        delete oldForm.roleType
        delete oldForm.organizeId
        delete oldForm.oauth
      } else {
        oldForm.userId = oldForm.id
      }
      // 5. 权限
      if (oldForm.userType !== '101') {
        delete oldForm.orgIds
      }
      if (this.form.roleType === '107') {
        oldForm.roleIds = this.orgList.map(item => item.id)
        this.orgList[0].obj.orgId && (oldForm.orgId = this.orgList[0].obj.orgId) // 组织账号默认
        oldForm.roleId = oldForm.roleIds[0]
      }
      // 6. 未知业务,暂不清楚为何如此写
      oldForm.logingName = oldForm.loginName
      // 添加操作对象参数 规则：账号名称-登录名
      let dataObject = `${oldForm.userName}`
      if (oldForm.loginName) {
        dataObject += `-${oldForm.loginName}`
      }
      oldForm.dataObject = dataObject
      // 7. 调请求接口,注意老接口请求成功后再请求新接口
      this.requestOldSubmit(oldForm)
    },
    async submitSuccess (data, msg) { // 3. 老接口保存成功后
      console.log('0. 开始调新接口')
      let onoff = await this.setNewParams()
      if (onoff) {
        console.log('5. 新表接口正常')
      }
      if (this.id&&!this.from) {
        this.openAllConfig(this.id)
      } else {
        this.openAllConfig(data)
        // 当角色权限不为区域、集团、项目时，data可能是账号名的提示
        data && !(/^(\d|\w|-)+$/.test(data)) && (await this.$alert(data))
      }
      return true
    },
    async openAllConfig (id) { // 4-1. 物管端APP待办项设置————区域、项目、集团
      // 100-区域、101-项目、102-集团
      let arr = ['100', '101', '102']
      if (this.id&&!this.from) {
        if (arr.includes(this.form.roleType) && !arr.includes(this.roleType)) {
          // 编辑 - 如果修改后的账号类型符合要求,且原先的账户类型不符合要求,则调接口
          this.requestToAllConfig(id)
        }
      } else if (arr.includes(this.form.roleType)) {
        // 新增 - 选择的账号类型符合要求，则调接口
        this.requestToAllConfig(id)
      }
    },
    async setNewParams () { // 4-2. 新接口
      const params = _.cloneDeep(this.form)
      let newForm = {}
      Object.keys({ ...this.newForm }).forEach(k => {
        if (k === 'id') { // 此处id不能用form,而必须是newForm的id
          newForm[k] = this.newForm[k]
          return
        }
        newForm[k] = params[k]
        if (k === 'mobileNum') {
          newForm[k] = params['phone']
        }
        if (k === 'adminDuty') {
          newForm[k] = params['duty']
        }
      })
      if(this.from == 'talent'){
        newForm.talentUserId = this.$route.query.id
      }
      const result = await this.requestNewSubmit(newForm)
      return result
    }
  },
};
</script>
<style lang="scss" scoped>
.mar-l {
  margin-left: 8px;
}
.f-wh {
  width: 520px;
}
.f-wh2 {
  width: 355px;
}
.Salary-wrapper {
  .talent-div {
    display: flex;
    align-items: center;
  }
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>

